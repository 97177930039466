import logo from './logo.png';
import './App.css';
import { TelephoneFill, EnvelopeFill, Twitter, Linkedin } from 'react-bootstrap-icons';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="App-text-color Title">
          Dominik Kopaczek
        </h1>
        <p className="App-text-color Subtitle">
          Software development / Investments
        </p>
        <table>
          <tr>
            <td>
              <a href="tel:+48576310548" className="App-text-color Without_undercsore">
                <TelephoneFill />  +48 576 310 548
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="mailto:kopaczek.d@gmail.com" className="App-text-color Without_undercsore">
                <EnvelopeFill />  kopaczek.d@gmail.com
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://twitter.com/dominikkopaczek" className="App-text-color Without_undercsore">
                <Twitter />  @dominikkopaczek
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://www.linkedin.com/in/dominik-kopaczek/" className="App-text-color Without_undercsore">
                <Linkedin />  dominik-kopaczek
              </a>
            </td>
          </tr>
        </table>
      </header>
    </div>
  );
}

export default App;
